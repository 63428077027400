<template>
  <b-modal
    id="modal-discount"
    ref="promo-modal"
    title="Шаблоны персональных скидок"
    hide-footer
    no-close-on-backdrop
  >
    <v-select
      v-model="selected"
      label="name"
      :options="discountsTemplates"
    />
    <b-form-checkbox
      v-model="isReplaceMode"
      class="mt-1"
    >
      Заменить существующие скидки
    </b-form-checkbox>
    <b-button
      variant="primary"
      class="mr-1 mt-2"
      :disabled="!selected"
      @click="updateDiscounts"
    >
      Сохранить
    </b-button>
  </b-modal>
</template>

<script>
import {
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import Ripple from 'vue-ripple-directive'
import crudModule from '@/libs/crud_module'

const discountsModule = crudModule({
  entity_name: 'personal-discount-template',
})
export default {
  name: 'AddDiscountsTemplatesModal',
  components: {
    vSelect,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      discountsTemplates: [],
      selected: null,
      isReplaceMode: false,
    }
  },
  created() {
    this.getTemplates()
  },
  methods: {
    async getTemplates() {
      this.discountsTemplates = await discountsModule.fetchItems().then(r => r.data.rows)
    },
    updateDiscounts() {
      this.$emit('select', {
        discounts: this.selected.instances,
        isReplaceMode: this.isReplaceMode,
      })

      this.selected = null
      this.isReplaceMode = false
      this.$refs['promo-modal'].hide()
    },
  },
}
</script>
