<template>
  <div>
    <div class="nav-bar">
      <div
        class="nav-bar__item"
        :class="{ active: step === 'general' }"
        @click="changeTab('general')"
      >
        Общее
      </div>
      <div
        class="nav-bar__item"
        :class="{ active: step === 'results' }"
        @click="changeTab('results')"
      >
        Результаты
      </div>
      <div
        class="nav-bar__item"
        :class="{ active: step === 'members' }"
        @click="changeTab('members')"
      >
        Участники
      </div>
      <div
        class="nav-bar__item"
        :class="{ active: step === 'new-results' }"
        @click="changeTab('new-results')"
      >
        Результаты новые
      </div>
    </div>
    <div v-if="step === 'new-results'">
      <ChipSync />
    </div>

    <div v-if="step === 'general'">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Общая информация
          </h4>
        </div>
        <div class="card-body">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="6">
                <b-form-group label="Название старта">
                  <b-form-input
                    v-model="start.name"
                    placeholder="Название старта"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Краткое название">
                  <b-form-input
                    v-model="start.short_name"
                    placeholder="Краткое название"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Серийный номер">
                  <b-form-input
                    v-model="start.serial_number"
                    placeholder="Серийный номер"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                class="switches"
              >
                <div class="switch">
                  <b-card-text class="mb-0">
                    Выводить на главную?
                  </b-card-text>
                  <b-form-checkbox
                    v-model="start.on_main_page"
                    checked="true"
                    class="custom-control-primary"
                    name="check-view"
                    switch
                  />
                </div>
                <div class="switch">
                  <b-card-text class="mb-0">
                    Регистрация на нашем сайте?
                  </b-card-text>
                  <b-form-checkbox
                    v-model="start.reg_on_site"
                    checked="true"
                    class="custom-control-primary"
                    name="check-reg"
                    switch
                  />
                </div>
                <div class="switch">
                  <b-card-text class="mb-0">
                    Отключить оплату?
                  </b-card-text>
                  <b-form-checkbox
                    v-model="start.payment_disabled"
                    checked="true"
                    class="custom-control-primary"
                    name="check-view"
                    switch
                  />
                </div>
                <div class="switch">
                  <b-form-group
                    label="Отключить проверку дублей?"
                  >
                    <b-form-checkbox
                      v-model="start.is_multi_registration"
                      checked="false"
                      class="custom-control-primary"
                      name="check-view"
                      switch
                    />
                  </b-form-group>
                </div>
                <div
                  v-if="start.payment_disabled"
                  class="inline-block"
                >
                  <b-form-group label="Способ оплаты">
                    <b-form-input
                      v-model="start.payment_type"
                      placeholder="Способ оплаты"
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col md="6">
                <b-form-group label="Координаты места проведения">
                  <b-form-input
                    v-model="start.coordinates"
                    placeholder="Координаты места проведения"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="!start.reg_on_site"
                md="6"
              >
                <b-form-group label="Ссылка на сайт регистрации">
                  <b-form-input
                    v-model="start.reg_link"
                    placeholder="Ссылка"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="URL">
                  <b-form-input
                    v-model="start.slug"
                    placeholder="URL"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Город проведения">
                  <v-select
                    v-model="start.city_id"
                    taggable
                    push-tags
                    :options="cities"
                    label="name"
                    :reduce="option => option.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Форма старта
          </h4>
        </div>
        <div
          v-if="Array.isArray(select_kinds_sport[0])"
          class="ml-2"
        >
          <start-days
            v-for="(day, index) in select_kinds_sport"
            v-show="index === active_day"
            :key="'sport_day_' + index"
            :age_groups="age_groups"
            :select_kinds_sport="day"
          />
        </div>

        <main-form
          v-else
          :select_kinds_sport="select_kinds_sport"
          :start="start"
          :age_groups="age_groups"
        />
      </div>

      <div class="card">
        <div class="card-body">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="6">
                <div class="upload-wrap">
                  <file-uploader
                    v-model="start.afisha_link"
                    :default="start.afishaLinkFile"
                    title="Афиша старта"
                  />
                </div>
              </b-col>
              <b-col md="6">
                <div class="upload-wrap">
                  <file-uploader
                    v-model="start.poster_link"
                    :default="start.posterLinkFile"
                    title="Обложка старта"
                  />
                </div>
              </b-col>
              <b-col md="6">
                <div class="upload-wrap">
                  <file-uploader
                    v-model="start.condition_file"
                    :default="start.conditionFile"
                    title="Положение старта"
                  />
                </div>
              </b-col>
              <b-col md="6">
                <div class="upload-wrap">
                  <file-uploader
                    v-model="start.small_image"
                    :default="start.smallImageFile"
                    title="Доп. изображение"
                  />
                </div>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
      <div class="card">
        <div class="card-header" />
        <div class="card-body">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="6">
                <b-form-group label="Полезные ссылки">
                  <div
                    v-for="(link, index) in useful_links"
                    :key="'links' + index"
                    class="links"
                  >
                    <svg
                      width="16"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="minus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      class="svg-inline--fa fa-minus fa-w-14"
                      @click="useful_links.splice(index, 1)"
                    >
                      <path
                        fill="currentColor"
                        d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                        class=""
                      />
                    </svg>
                    <b-form-input
                      v-model="link.text"
                      class="links__input"
                      placeholder="Название"
                    />
                    <b-form-input
                      v-model="link.url"
                      class="links__input"
                      placeholder="Адрес"
                    />
                  </div>
                  <b-button
                    variant="primary"
                    class="mr-1"
                    @click="useful_links.push({ text: '', url: '' })"
                  >
                    Добавить ссылку
                  </b-button>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Социальные сети">
                  <div
                    v-for="(social, index) in socials"
                    :key="'social' + index"
                    class="links"
                  >
                    <svg
                      width="16"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="minus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      class="svg-inline--fa fa-minus fa-w-14"
                      @click="socials.splice(index, 1)"
                    >
                      <path
                        fill="currentColor"
                        d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                        class=""
                      />
                    </svg>
                    <b-form-input
                      v-model="social.url"
                      placeholder="Введите ссылку в формате http/https"
                      class="links__input"
                    />
                    <b-form-input
                      v-model="social.code"
                      placeholder="Введите название соц. сети"
                      class="links__input"
                    />
                  </div>

                  <b-button
                    variant="primary"
                    class="mr-1"
                    @click="socials.push({ code: null, url: null })"
                  >
                    Добавить ссылку
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <div class="card-body">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="12">
                <b-form-group
                  v-for="(organizer, index) in organizers"
                  :key="'organizer' + index"
                  :label="'Организатор ' + (index + 1)"
                >
                  <div class="organizer">
                    <div class="organizer__wrap">
                      <svg
                        width="16"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="minus"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        class="svg-inline--fa fa-minus fa-w-14"
                        @click="organizers.splice(index, 1)"
                      >
                        <path
                          fill="currentColor"
                          d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                          class=""
                        />
                      </svg>
                      <b-form-input
                        v-model="organizer.name"
                        class="organizer__input"
                        placeholder="Имя организатора"
                      />
                      <b-form-input
                        v-model="organizer.phone"
                        class="organizer__input"
                        placeholder="Телефон"
                      />
                      <div class="organizer__switch">
                        <b-card-text class="mb-0">
                          WhatsApp
                        </b-card-text>
                        <b-form-checkbox
                          v-model="organizer.messengers"
                          checked="true"
                          class="custom-control-primary"
                          name="check-whats"
                          value="WA"
                          switch
                        />
                      </div>
                      <div class="organizer__switch">
                        <b-card-text class="mb-0">
                          Telegram
                        </b-card-text>
                        <b-form-checkbox
                          v-model="organizer.messengers"
                          checked="true"
                          class="custom-control-primary"
                          name="check-tele"
                          value="TG"
                          switch
                        />
                      </div>
                      <div class="organizer__switch">
                        <b-card-text class="mb-0">
                          Viber
                        </b-card-text>
                        <b-form-checkbox
                          v-model="organizer.messengers"
                          checked="true"
                          class="custom-control-primary"
                          name="check-vib"
                          value="VB"
                          switch
                        />
                      </div>
                    </div>

                    <div>
                      Соц. сети
                      <div
                        v-for="(link, index) in organizer.social_networks"
                        :key="'organizer-social' + index"
                        class="links"
                      >
                        <svg
                          width="16"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="minus"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          class="svg-inline--fa fa-minus fa-w-14"
                          @click="organizer.social_networks.splice(index, 1)"
                        >
                          <path
                            fill="currentColor"
                            d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                            class=""
                          />
                        </svg>
                        <b-form-input
                          v-model="link.url"
                          class="links__input"
                          placeholder="Ссылка на соц. сеть"
                        />
                        <b-form-input
                          v-model="link.code"
                          class="links__input"
                          placeholder="Код соц. сети"
                        />

                        <svg
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-popover.html.hover.bottom="
                            'Instagram - \ninstagram\n <br> Telegram - \ntelegram\n <br> Vkontakte - \nvk\n <br> <a href=\'https://fontawesome.com/v4.7/icons/\'>Просмотреть все коды</a>'
                          "
                          variant="outline-primary"
                          width="20"
                          class="ml-1"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="question"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <b-button
                    variant="primary"
                    class="mr-1"
                    @click="
                      organizer.social_networks.push({ code: null, url: null })
                    "
                  >
                    <b-spinner
                      v-if="isloading"
                      small
                    />
                    Добавить соц. сеть
                  </b-button>
                </b-form-group>

                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="
                    organizers.push({
                      name: null,
                      phone: null,
                      messengers: [],
                      social_networks: [
                        {
                          code: null,
                          name: null,
                        },
                      ],
                    })
                  "
                >
                  Добавить организатора
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
      <div class="card">
        <div class="card-header" />
        <div class="card-body">
          <h4 class="card-title">
            Описание
          </h4>
          <div class="my-1">
            Полное описание
          </div>
          <quill-editor
            ref="myQuillEditor1"
            v-model="start.description"
            @change="onEditorChange($event)"
          />
          <div class="my-1 mt-5">
            Краткое описание
          </div>
          <quill-editor
            ref="myQuillEditor2"
            v-model="start.condition_short"
            @change="onEditorChange($event)"
          />
        </div>
      </div>
      <div class="card">
        <div class="card-header" />
        <div class="card-body">
          <b-form-group label="Спонсоры">
            <div
              v-for="(sponsor, index) in sponsors"
              :key="'sponsor' + index"
            >
              <div class="links">
                <b-col md="6">
                  <div class="flex-wrapper">
                    <svg
                      width="16"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="minus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      class="svg-inline--fa fa-minus fa-w-14"
                      @click="sponsors.splice(index, 1)"
                    >
                      <path
                        fill="currentColor"
                        d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                        class=""
                      />
                    </svg>
                    <b-form-input
                      v-model="sponsor.name"
                      placeholder="Название"
                      class="links__input"
                    />
                  </div>
                </b-col>

                <b-col md="6">
                  <b-form-input
                    v-model="sponsor.site"
                    placeholder="Сайт"
                    class="links__input"
                  />
                </b-col>
              </div>
              <div>
                <b-col
                  style="margin: 20px 0"
                  md="6"
                >
                  <file-uploader
                    v-model="sponsor.logo"
                    title="Логотип"
                  />
                </b-col>
              </div>
            </div>

            <b-button
              variant="primary"
              class="mr-1"
              @click="sponsors.push({ name: '', site: '', logo: {} })"
            >
              Добавить спонсора
            </b-button>
          </b-form-group>
        </div>
      </div>
      <div class="card">
        <div class="card-header" />
        <div class="card-body">
          <b-form-group label="Схема проезда">
            <b-form-input class="links__input" />

            <div class="switch">
              <b-card-text class="mb-0">
                Сервис поиска попутчиков
              </b-card-text>
              <b-form-checkbox
                checked="true"
                class="custom-control-primary"
                name="check-view"
                switch
              />
            </div>
          </b-form-group>
        </div>
      </div>

      <div class="card">
        <div class="card-header" />
        <div class="card-body">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="12">
                <b-form-group label="Программа старта" />
              </b-col>

              <b-col md="6">
                <div class="links">
                  <b-form-group class="time-pick">
                    <span>Начало регистрации*</span>
                    <flat-pickr
                      v-model="start.registration_start_date"
                      class="form-control"
                      :config="config"
                    />
                  </b-form-group>

                  <b-form-group class="time-pick">
                    <span>Конец регистрации*</span>
                    <flat-pickr
                      v-model="start.registration_end_date"
                      class="form-control"
                      :config="config"
                    />
                  </b-form-group>
                </div>
                <b-col
                  md="12"
                  style="padding: 0"
                >
                  <div class="links">
                    <b-form-group class="time-pick">
                      <span>Начало старта*</span>
                      <flat-pickr
                        v-model="start.start_date"
                        class="form-control"
                        :config="config"
                      />
                    </b-form-group>

                    <b-form-group class="time-pick">
                      <span>Конец старта*</span>
                      <flat-pickr
                        v-model="start.end_date"
                        class="form-control"
                        :config="config"
                      />
                    </b-form-group>
                  </div>
                </b-col>
              </b-col>

              <b-form-group>
                <b-col
                  md="12"
                  class="switches"
                >
                  <div class="switch">
                    <b-card-text class="mb-0">
                      Закрыть регистрацию на старт
                    </b-card-text>
                    <b-form-checkbox
                      v-model="start.isClosed"
                      checked="true"
                      class="custom-control-primary"
                      name="check-view"
                      switch
                    />
                  </div>
                  <div class="switch">
                    <b-card-text class="mb-0">
                      Дата старта изменена
                    </b-card-text>
                    <b-form-checkbox
                      v-model="start.isRescheduled"
                      checked="true"
                      class="custom-control-primary"
                      name="check-reg"
                      switch
                    />
                  </div>
                  <div class="switch">
                    <b-card-text class="mb-0">
                      Регистрация на месте
                    </b-card-text>
                    <b-form-checkbox
                      checked="true"
                      class="custom-control-primary"
                      name="check-reg"
                      switch
                    />
                  </div>
                </b-col>
              </b-form-group>
            </b-row>
          </b-form>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Промокоды
          </h4>
        </div>
        <div class="card-body">
          <div class="promo__items">
            <div
              v-for="(item, i) in promocodes"
              :key="'promo_' + i"
              class="promo__item"
            >
              <div
                class="promo__item-del"
                @click="deletePromocode(item, i)"
              >
                <svg
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                  />
                </svg>
              </div>

              <b-form-input
                v-model="item.code"
                placeholder="Код"
                class="inline-block mb-1"
              />
              <b-form-input
                v-model.number="item.value"
                type="number"
                no-wheel
                placeholder="Скидка в %"
                class="inline-block mb-1"
              />
              <b-form-input
                v-model.number="item.usageLimitInitial"
                type="number"
                no-wheel
                placeholder="Лимит использований"
                class="inline-block"
              />
              <span
                v-if="item.usageLimitInitial"
                class="mt-1"
              >Доступно {{ item.usageAvailable || 0
                }} из {{ item.usageLimitInitial }}</span>
            </div>
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.modal-3
            type="submit"
            variant="primary"
            class="mr-1"
            @click="promocodes.push({ code: null, value: null, usageLimitInitial: null })"
          >
            Добавить промокод
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.modal-promo
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Загрузить из шаблона
          </b-button>
          <add-promos-templates-modal @select="addPromosFromTemplate" />
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Персональные скидки
          </h4>
        </div>
        <div class="card-body">
          <div class="discount__items">
            <div
              v-for="(item, i) in personalDiscounts"
              :key="'discount_' + i"
              class="discount__item"
            >
              <div
                class="promo__item-del"
                @click="deletePersonalDiscount(item, i)"
              >
                <svg
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                  />
                </svg>
              </div>

              <v-select
                v-model="item.user"
                label="name"
                :filterable="false"
                :options="ddUsers"
                class="mb-1"
                @search="onSearch"
              >
                <template slot="no-options">
                  Введите Почту пользователя
                </template>
                <template
                  slot="option"
                  slot-scope="option"
                >
                  <div class="d-center">
                    {{ `${option.id} - ${option.name} ${option.surname}(${option.email})` }}
                  </div>
                </template>
                <template
                  slot="selected-option"
                  slot-scope="option"
                >
                  <div class="selected d-center">
                    {{ `${option.name} ${option.surname}(${option.email})` }}
                  </div>
                </template>
              </v-select>
              <b-form-input
                v-model.number="item.value"
                type="number"
                no-wheel
                placeholder="Скидка в %"
                class="inline-block mb-1"
              />
            </div>
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.modal-3
            type="submit"
            variant="primary"
            class="mr-1"
            @click="personalDiscounts.push({ user: null, value: null })"
          >
            Добавить скидку
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.modal-discount
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Загрузить из шаблона
          </b-button>
          <add-discounts-templates-modal @select="addPersonalDiscountsFromTemplate" />
        </div>
      </div>
      <!-- <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Скидки по возрасту
          </h4>
        </div>
        <div class="card-body">
          <div class="discount__items">
            <div
              v-for="(item, i) in discount"
              :key="'discount_' + i"
              class="discount__item"
            >
              <div
                class="discount__item-del"
                @click="discount.splice(i, 1)"
              >
                <svg
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                  />
                </svg>
              </div>
              <b-form-input
                v-model.number="item.c_to"
                type="number"
                placeholder="От"
                style="margin-bottom: 10px"
              />
              <b-form-input
                v-model.number="item.c_from"
                type="number"
                placeholder="До"
                style="margin-bottom: 10px"
              />
              <div class="switch">
                <b-card-text class="mb-0">
                  Скидка в %
                </b-card-text>
                <b-form-checkbox
                  v-model="item.percent"
                  class="custom-control-primary"
                  name="check-reg"
                  switch
                />
              </div>
              <b-form-input
                v-model.number="item.value"
                type="number"
                :placeholder=" item.percent ? '% скидки' : 'скидка(руб)'"
              />
            </div>
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="
              discount.push({
                c_to: null,
                c_from: null,
                value: null,
                percent: false,
              })
            "
          >
            Добавить скидку
          </b-button>
        </div>
      </div> -->
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Фильтрация
          </h4>
        </div>
        <div class="card-body">
          <b-row>
            <b-col md="6">
              <b-form-group label="Виды спорта" />
              <v-select
                v-model="kindOfSports_local"
                multiple
                :reduce="e => e.id"
                label="name"
                :options="kindOfSports"
              />
            </b-col>
            <b-col md="6">
              <b-form-group label="Сезон" />
              <b-form-select
                v-model="start.season_id"
                :options="seasons"
                text-field="name"
                value-field="id"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Банковская статистика
          </h4>
        </div>
        <div class="card-body">
          <start-statistic :start_id="+$route.params.id" />
        </div>
      </div>
    </div>
    <div v-if="step === 'results'">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Результаты старта
          </h4>
        </div>
        <div class="card-body">
          <div
            v-for="(result, index) in start.results"
            :key="'result-link' + index"
          >
            <b-button
              variant="primary"
              size="sm"
              class="mb-1"
              @click="start.results.splice(index, 1)"
            >Удалить
            </b-button>
            <file-uploader
              v-model="result.fileID"
              :default="result.file"
              :title="'Файл ' + (index + 1)"
            />
            <b-form-input
              v-model="result.name"
              class="links__input"
              placeholder="Название файла"
            />
          </div>
          <b-button
            variant="primary"
            class="mr-1"
            @click="addResultLink"
          >Добавить результат
          </b-button>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Загрузка результатов Excel
          </h4>
        </div>
        <div class="card-body">
          <div
            v-for="(column, ind) in excelResultColumns"
            :key="'excel_column_' + ind"
          >
            <b-form-group :label="'Circle' + (ind + 1)">
              <b-form-input
                v-model="column.value"
                class="links__input"
                placeholder="Название колонки"
              />
              <b-button
                variant="primary"
                size="sm"
                class="mb-1"
                @click="excelResultColumns.splice(ind, 1)"
              >Удалить
              </b-button>
            </b-form-group>
          </div>
          <b-button
            variant="primary"
            class="mb-5"
            @click="excelResultColumns.push({ value: '' })"
          >Добавить название колонки
          </b-button>

          <b-form-group label="Забеги">
            <div
              v-for="(run, index) in runs"
              :key="'run' + index"
            >
              <div class="links">
                <b-col md="6">
                  <div class="flex-wrapper">
                    <svg
                      width="16"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="minus"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      class="svg-inline--fa fa-minus fa-w-14"
                      @click="deleteRun(index)"
                    >
                      <path
                        fill="currentColor"
                        d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                        class=""
                      />
                    </svg>
                    <b-form-input
                      v-model="run.name"
                      placeholder="Название"
                      class="links__input"
                    />
                  </div>
                </b-col>

                <b-col md="6">
                  <flat-pickr
                    v-model="run.time"
                    placeholder="Дата"
                    class="form-control"
                    :config="config"
                  />
                </b-col>
              </div>
            </div>

            <b-button
              variant="primary"
              class="mr-1"
              @click="addRun()"
            >
              Добавить забег
            </b-button>
          </b-form-group>

          <excel-uploader
            :on-success="successResultUpload"
            :runs="runs"
          />
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Результаты из файла
          </h4>
        </div>
        <div class="card-body">
          <start-members-results
            ref="fileResultsComponent"
            :start_id="$route.params.id"
            :columns="excelResultColumns"
          />
        </div>
      </div>
    </div>
    <div v-if="step === 'members'">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Зарегистрированные участники
          </h4>
        </div>
        <div class="card-body">
          <div>
            <b-modal
              id="modal-2"
              ref="delete-modal"
              title="Удалить регистрацию"
              cancel-title="Отмена"
              ok-title="Удалить"
              @ok="deleteRegistration"
            >
              <p>Вы уверены, что хотите удалить регистрацию и все данные о её участниках? Это действие нельзя будет
                отменить.</p>
            </b-modal>
            <b-modal
              id="modal-1"
              ref="edit-modal"
              title="Редактирование"
              :hide-footer="true"
            >
              <edit-member
                :member="currentMember"
                :modal-ref="$refs['edit-modal']"
                :cities="cities"
                @save="getMembersFromStart"
              />
            </b-modal>
            <b-form-group class="search-wrap">
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="searchMember"
                  placeholder="Поиск"
                  type="search"
                  class="d-inline-block"
                  @update="
                    (search, loading) => getFilteredMembers(search, loading)
                  "
                />
              </div>
            </b-form-group>
            <b-table
              :items="members"
              :fields="registrationFields"
              striped
              responsive
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              class="first-table"
            >
              <template #cell(actions)="data">
                <div class="icons-container">
                  <b-form-checkbox
                    v-model="selectedGroups"
                    :value="data.item.id"
                  />
                  <div
                    v-b-modal.modal-2
                    @click="currentRegForDelete = data.item.id"
                  >
                    <svg
                      style="margin-right: 10px"
                      data-v-3f2fc030=""
                      fill="#7367f0"
                      width="14"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        data-v-3f2fc030=""
                        d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z"
                      />
                    </svg>
                  </div>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#fff"
                    :class="['bi bi-chevron-down', data.detailsShowing ? 'rotate-chevron' : '']"
                    viewBox="0 0 16 16"
                    @click="data.toggleDetails()"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </div>
              </template>
              <template #cell(single_member)="data">
                {{ checkRegistrationForSingleMember(data.item.members) }}
              </template>
              <template #cell(registration_date)="data">
                {{ data.item.registration_date ? $moment(data.item.registration_date).format('DD.MM.YYYY') : 'Нет даты регистрации'
                }}
              </template>
              <template #cell(payment)="data">
                <div class="table-payment-row">
                  <member-status
                    :status="data.item.payment"
                    :index="data.item.id"
                  />
                  <b-button
                    v-if="!data.item.payment"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-success"
                    class="btn-icon ml-1"
                    @click="refreshPaymentLink(data.item)"
                  >
                    <b-spinner
                      v-if="data.item.disabled"
                      small
                    />
                    <feather-icon
                      v-else
                      icon="DollarSignIcon"
                    />
                  </b-button>
                </div>
              </template>
              <template v-slot:row-details="row">
                <b-table
                  class="second-table"
                  :items="row.item.members"
                  :fields="memberFields"
                >
                  <template #cell(actions)="data">
                    <div class="icons-container">
                      <span
                        v-b-modal.modal-1
                        @click="currentMember = data.item"
                      >
                        <svg
                          data-v-414f8174=""
                          xmlns="http://www.w3.org/2000/svg"
                          width="14px"
                          height="14px"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-edit-2"
                        >
                          <path
                            data-v-414f8174=""
                            d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                          />
                        </svg>
                      </span>
                    </div>
                  </template>
                  <template #cell(createdAt)="data">
                    {{ $moment(data.item.createdAt).format('DD.MM.YYYY') }}
                  </template>
                  <template #cell()="data">
                    <span>{{ getUserAnswer(data) }}</span>
                  </template>
                </b-table>
              </template>
            </b-table>
            <infinite-loading
              spinner="bubbles"
              @infinite="infiniteScrollMembers"
            />
            <download-start-members-modal :distances="start.distances" />
          </div>
        </div>
      </div>
    </div>
    <b-col class="buttonsContainer">
      <div
        v-if="step === 'members'"
        class="membersTabButtons"
      >
        <b-button
          variant="primary"
          :disabled="downloadDisabled"
          @click="$bvModal.show('bv-download-members-start-modal')"
        >
          <b-spinner
            v-if="downloadDisabled"
            small
          />
          {{ downloadDisabled ? 'Скачивание...' : 'Скачать Excel' }}
        </b-button>
        <b-button
          variant="primary"
          @click="changePayment('paid')"
        >
          Отметить оплаченными
        </b-button>
        <b-button
          variant="primary"
          @click="changePayment('cancel')"
        >
          Отменить оплату
        </b-button>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        @click="backupState()"
      >
        {{ 'Резервирование' }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        @click="restoreState"
      >
        {{ 'Восстановление' }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        :disabled="isloading"
        @click="saveItem"
      >
        {{ 'Сохранить' }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  BTable,
  VBPopover,
  BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import InfiniteLoading from 'vue-infinite-loading'
import _ from 'lodash'
import FileUploader from '@/global-components/FileUploader'
import StartDays from './StartDays'
import crud_module from '../../libs/crud_module'
import EditMember from '@/views/starts/EditMember'
import ExcelUploader from '../../global-components/ExcelUploader'
import StartMembersResults from './StartMembersResults'
import StartStatistic from './StartStatistic'
import MainForm from '@/views/starts/new-version/MainForm'
import MemberResults from '@/views/starts/chip-sync/ChipSync.vue'
import ChipStatistic from '@/views/starts/chip-sync/ChipSync.vue'
import ChipSync from '@/views/starts/chip-sync/ChipSync.vue'
import MemberStatus from './MemberStatus.vue'
import DownloadStartMembersModal from './DownloadStartMembersModal.vue'
import AddPromosTemplatesModal from './AddPromosTemplatesModal.vue'
import AddDiscountsTemplatesModal from './templates/discounts/AddDiscountsTemplatesModal.vue'

const start_module = crud_module({
  entity_name: 'start',
})
const member_module = crud_module({
  entity_name: 'member-start',
})
const kind_of_sports = crud_module({
  entity_name: 'kind-of-sport',
})
const seasons = crud_module({
  entity_name: 'season',
})
const cities = crud_module({
  entity_name: 'city',
})
const questions = crud_module({
  entity_name: 'member-start/extra',
})

const payment_module = crud_module({
  entity_name: 'member-start/change-payment',
})

const usersModule = crud_module({
  entity_name: 'user',
})

const stateKeys = [
  'start',
  'sponsors',
  'organizers',
  'socials',
  'useful_links',
  'promocodes',
  'personalDiscounts',
  'kindOfSports_local',
  'select_kinds_sport',
  'excelResultColumns',
]

export default {
  name: 'StartPage',
  components: {
    ChipSync,
    ChipStatistic,
    MemberResults,
    MainForm,
    StartStatistic,
    StartMembersResults,
    ExcelUploader,
    EditMember,
    FileUploader,
    BTable,
    StartDays,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BSpinner,
    BCardText,
    ToastificationContent,
    flatPickr,
    BFormSelect,
    vSelect,
    MemberStatus,
    DownloadStartMembersModal,
    InfiniteLoading,
    AddPromosTemplatesModal,
    AddDiscountsTemplatesModal,
  },

  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      debounce: null,
      step: 'general',
      excelResult: null,
      members: [],
      discount: [],
      promocodes: [],
      personalDiscounts: [],
      ddUsers: [],
      currentMember: null,
      selectedGroups: [],
      age_counting: null,
      kindOfSport: null,
      kindOfSports: [],
      kindOfSports_local: [],
      seasons: [],
      cities: [],
      excelResultColumns: [],
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
      sponsors: [],
      runs: [],
      age_groups: {
        male: [
          {
            age_from: '',
            age_to: '',
          },
        ],
        female: [
          {
            age_from: '',
            age_to: '',
          },
        ],
      },
      registrationFields: [
        {
          key: 'actions',
          label: 'Действия',
        },
        'id',
        {
          key: 'single_member',
          label: 'ФИО',
        },
        {
          key: 'registration_date',
          label: 'Дата регистрации',
        },
        {
          key: 'price',
          label: 'Стоимость',
          sortable: true,
          formatter: value => Number(value) / 100,
        },
        {
          key: 'promocode.code',
          label: 'Промо',
        },
        {
          key: 'success_payment_reason',
          label: 'Доп инфо',
        },
        {
          key: 'payment',
          label: 'Статус',
          sortable: true,
        },
      ],
      memberFields: [
        {
          key: 'empty',
          label: '',
        },
        {
          key: 'actions',
          label: 'Действия',
        },
        'id',
        {
          key: 'name',
          label: 'Имя',
        },
        {
          key: 'surname',
          label: 'Фамилия',
        },
        {
          key: 'distance_relation.name',
          label: 'Дистанция',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'Телефон',
        },
        {
          key: 'gender',
          label: 'Пол',
          sortable: true,
        },
        {
          key: 'team',
          label: 'Команда',
          sortable: true,
        },
        {
          key: 'city',
          label: 'Город',
        },
        {
          key: 'age_group.name',
          label: 'Группа',
        },
      ],
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
      select_kinds_sport: [],
      active_day: 0,
      isloading: false,
      downloadDisabled: false,
      start: {
        city_id: null,
        season_id: null,
        isRescheduled: false,
        isClosed: false,
      },
      organizers: [],
      useful_links: [],
      socials: [],
      sortBy: 'distance',
      sortDesc: false,
      searchMember: '',
      editorOptions: {
        modules: {
          toolbar: true,
        },
        placeholder: 'Введите текст...',
        readOnly: false,
        theme: 'snow',
      },
      scrollState: undefined,
      skipCountMembers: 0,
      maxResultCountMembers: 20,
      currentRegForDelete: null,
      selectedRegToDelete: null,
      templatePromos: [],
      templatePersonalDiscounts: [],
    }
  },
  watch: {
    runs: {
      handler(val, oldVal) {
        localStorage.setItem('runs', JSON.stringify(this.runs))
      },
      deep: true,
    },
    '$route.query.tab': {
      handler(value) {
        if (value) {
          this.step = value
        } else {
          this.$router.go(-1)
        }
      },
    },
  },
  async created() {
    if (this.$route.query.tab) {
      this.step = this.$route.query.tab
    } else {
      this.changeTab('general')
    }
    await this.getSeasons()
    await this.getCities()
    await this.getKindOfSports()
    if (this.$route.params.id === 'new') {
      // this.start.season_id = this.seasons.filter(e => e.is_primary)
      //   && this.seasons.filter(e => e.is_primary)[0].id
      return
    }

    await this.updateDataFromStart()
  },
  mounted() {
    if (localStorage.runs) {
      this.runs = JSON.parse(localStorage.getItem('runs'))
    }
  },
  methods: {
    backupState(lsKey = 'componentState') {
      const statesToSave = {}
      stateKeys.forEach(key => {
        if (this.$data[key] !== undefined) {
          statesToSave[key] = this.$data[key]
        }
      })
      localStorage.setItem(lsKey, JSON.stringify(statesToSave))
      if (lsKey === 'componentState') {
        this.$toast.success('Данные сохранены в локальном хранилище')
      }
    },
    restoreState() {
      this.backupState('backupComponentState')
      const savedStates = localStorage.getItem('componentState')
      if (savedStates) {
        const parsedStates = JSON.parse(savedStates)
        stateKeys.forEach(key => {
          if (parsedStates[key] !== undefined) {
            this.$data[key] = parsedStates[key]
          }
        })
      }
    },
    addPromosFromTemplate(promos) {
      if (this.templatePromos.length !== 0) {
        const { template_id } = this.templatePromos[0]
        this.promocodes = this.promocodes.filter(promo => promo.template_id !== template_id)
      }
      this.templatePromos = promos
      this.promocodes = this.promocodes.concat(promos)
    },
    deletePromocode(promocode, index) {
      if (this.templatePromos.length && promocode.template_id) {
        const indexDeletedPromo = this.templatePromos.findIndex(item => item.template_id === promocode.template_id && item.id === promocode.id)
        this.templatePromos.splice(indexDeletedPromo, 1)
      }
      this.promocodes.splice(index, 1)
    },
    isExternalLink(url, code) {
      try {
        const link = new URL(url)
        return link.protocol === 'http:' || link.protocol === 'https:'
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            icon: 'BellIcon',
            text: `Введите адрес социальной сети ${code} в формате http или https`,
            variant: 'danger',
          },
        })
        return false
      }
    },
    addPersonalDiscountsFromTemplate({ discounts, isReplaceMode }) {
      if (isReplaceMode) {
        this.personalDiscounts = []
        this.templatePersonalDiscounts = []
      }

      const newTemplateId = discounts[0]?.template_id

      if (newTemplateId && this.templatePersonalDiscounts.some(t => t.template_id === newTemplateId)) {
        this.personalDiscounts = this.personalDiscounts.filter(
          discount => discount.template_id !== newTemplateId,
        )
      }

      this.templatePersonalDiscounts = [...this.templatePersonalDiscounts, ...discounts]

      this.personalDiscounts = [...this.personalDiscounts, ...discounts]
    },
    deletePersonalDiscount(discount, index) {
      if (this.templatePersonalDiscounts.length && discount.template_id) {
        const indexDeletedDiscount = this.templatePromos.findIndex(item => item.template_id === discount.template_id && item.id === discount.id)
        this.templatePersonalDiscounts.splice(indexDeletedDiscount, 1)
      }
      this.personalDiscounts.splice(index, 1)
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: _.debounce((loading, search, vm) => {
      usersModule.fetchItems({
        email: search,
      }).then(res => {
        vm.ddUsers = res.data.rows
        loading(false)
      })
    }, 350),
    changeTab(tab) {
      this.$router.push({ query: { tab } })
    },
    onEditorChange(event) {
      event.quill.blur()
    },
    toggleDetails() {
    },
    checkRegistrationForSingleMember(members) {
      if (!members || members.length === 0) {
        return 'Нет участников'
      }
      if (members.length > 1) {
        return 'Более одного участника'
      }

      return `${members[0]?.name} ${members[0]?.surname}`
    },
    async infiniteScrollMembers($state) {
      this.scrollState = $state
      const payload = {
        start_id: this.$route.params.id,
        skipCount: this.skipCountMembers,
        maxResultCount: this.maxResultCountMembers,
        admin: 1,
      }
      await member_module.fetchItems(payload)
        .then(res => {
          if (res.data.rows.length > 0) {
            this.members.push(...res.data.rows)
            this.skipCountMembers += this.maxResultCountMembers
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // selectGroups(event, id) {
    //   members.forEach(member => {
    //     if (event) {
    //       this.selectedGroups.push(member.id)
    //     } else {
    //       const index = this.selectedMembers.indexOf(member.id)
    //       this.selectedMembers.splice(index, 1)
    //     }
    //   })
    // },
    async updateDataFromStart() {
      try {
        const results = await Promise.allSettled([
          start_module.fetchItem(`admin/${this.$route.params.id}`),
          start_module.arbitraryRequest.get(`${this.$route.params.id}/personal-discounts`),
          start_module.arbitraryRequest.get(`${this.$route.params.id}/distances`),
        ])

        const [startDataResult, personalDiscountsResult, distancesResult] = results

        if (startDataResult.status === 'fulfilled') {
          this.start = startDataResult.value.data
        } else {
          console.error('Ошибка получения startData:', startDataResult.reason)
        }

        if (personalDiscountsResult.status === 'fulfilled') {
          this.personalDiscounts = personalDiscountsResult.value
        } else {
          console.error('Ошибка получения personalDiscounts:', personalDiscountsResult.reason)
        }

        if (distancesResult.status === 'fulfilled') {
          this.select_kinds_sport = distancesResult.value
        } else {
          console.error('Ошибка получения distancesResult:', distancesResult.reason)
        }

        if (this.start.sponsors && this.start.sponsors.length > 0) {
          this.start.sponsors.forEach(sponsor => {
            if (sponsor.logo) {
              sponsor.logo = JSON.parse(sponsor.logo)
            }
          })

          this.sponsors = this.start.sponsors
        }
        if (this.start.age_counting) {
          this.age_counting = this.start.age_counting
        }
        if (this.start.social_networks && this.start.social_networks.length > 0) {
          this.socials = this.start.social_networks
        }
        if (this.start.discount && this.start.discount.length > 0) {
          this.discount = this.start.discount
        }
        if (this.start.promocodes && this.start.promocodes.length > 0) {
          this.promocodes = this.start.promocodes
        }
        if (this.start.useful_links && this.start.useful_links.length > 0) {
          this.useful_links = this.start.useful_links
        }
        if (this.start.kindOfSports && this.start.kindOfSports.length > 0) {
          this.kindOfSports_local = this.start.kindOfSports.map(e => e.id)
        }
        if (this.start.organizers && this.start.organizers.length > 0) {
          this.start.organizers.forEach(org => {
            if (org.messengers) {
              org.messengers = org.messengers.split(',')
            } else {
              org.messengers = []
            }
          })
          this.organizers = this.start.organizers
        }

        if (this.start.result_columns_json) {
          this.excelResultColumns = JSON.parse(this.start.result_columns_json)
        }

        await this.getQuestions()
        if (this.start.registrationsCount) {
          this.start.disabledDistances = JSON.parse(JSON.stringify(this.start.distances))
        }
      } catch (e) {
        console.log(e)
      }
    },
    deleteRun(index) {
      this.runs.splice(index, 1)
      localStorage.setItem('runs', JSON.stringify(this.runs))
    },
    addRun() {
      this.runs.push({ name: '', time: '' })
      localStorage.setItem('runs', JSON.stringify(this.runs))
    },
    async getKindOfSports() {
      const data = await kind_of_sports.fetchItems()
      this.kindOfSports = data.data && data.data.rows
    },
    async getSeasons() {
      const data = await seasons.fetchItems()
      this.seasons = data.data && data.data.rows
    },
    async getQuestions() {
      const data = await questions.fetchItems({ start_id: this.$route.params.id })
      if (data.data.questionList.length !== 0) {
        data.data.questionList.forEach(question => {
          this.memberFields.push({
            key: `${question.id}`,
            label: question.title,
          })
        })
      }
    },
    getUserAnswer(data) {
      if (Number(data.field.key)) {
        const currentQuestion = data.item.additional_field_answers.find(item => item.additionalFieldId === Number(data.field.key))
        if (currentQuestion) {
          if (currentQuestion.bool !== null) {
            return currentQuestion.bool ? 'Да' : 'Нет'
          }
          if (currentQuestion.date) {
            return this.$moment(currentQuestion.date).format('DD.MM.YYYY')
          }
          if (currentQuestion.number) {
            return currentQuestion.number
          }
          if (currentQuestion.string) {
            return currentQuestion.string
          }
          if (currentQuestion.options) {
            return currentQuestion.options.map(item => item.title).join(', ')
          }
        } else {
          return '-'
        }
      } else {
        return data.value
      }
    },
    async deleteRegistration() {
      await member_module.deleteItem(this.currentRegForDelete)
        .then(async () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'BellIcon',
              text: 'Регистрация удалена успешно',
              variant: 'success',
            },
          })
          await this.getMembersFromStart()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'BellIcon',
              text: error.response.data.message,
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.$refs['delete-modal'].hide()
        })
    },
    async getCities() {
      const data = await cities.fetchItems()
      this.cities = data.data && data.data.rows
    },
    async successResultUpload(data) {
      const result = await this.$http
        .post('/member-result/bulk', {
          start_id: this.$route.params.id,
          results: data.results.map(e => {
            let circles = []
            for (const key in e) {
              if (key.indexOf('circle') === 0) {
                circles.push(e[key])
              }
            }
            circles = JSON.stringify(circles)
            return {
              ...e,
              circles,
              start_id: this.$route.params.id,
            }
          }),
        })
        .then(e => e.data)
      this.$refs.fileResultsComponent.refreshResults()
    },
    async getMembersFromStart() {
      this.skipCountMembers = 0
      const payload = {
        start_id: this.$route.params.id,
        skipCount: this.skipCountMembers,
        maxResultCount: this.maxResultCountMembers,
        admin: 1,
      }
      await member_module.fetchItems(payload)
        .then(res => {
          this.members = res.data.rows
          this.skipCountMembers += this.maxResultCountMembers
        })
    },
    async refreshPaymentLink(member) {
      if (!member.price) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Предупреждение',
            icon: 'BellIcon',
            text: 'Необходимо установить цену участнику, чтобы сформировать ссылку на оплату',
            variant: 'warning',
          },
        })
        return false
      }
      if (!member.hasOwnProperty('disabled')) {
        this.$set(member, 'disabled')
      }
      member.disabled = true
      const result = await this.$http
        .put(`/member-start/${member.id}/repayment`)
        .then(r => r.data)
      if (result.payment.formUrl) {
        const data = [
          new ClipboardItem({
            'text/plain': new Blob([result.payment.formUrl], {
              type: 'text/plain',
            }),
          }),
        ]
        navigator.clipboard.write(data).then(
          () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Скопировано',
                icon: 'BellIcon',
                text: 'Новая ссылка на оплату скопирована в буфер обмена',
                variant: 'success',
              },
            })
          },
          () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка',
                icon: 'BellIcon',
                text: 'Невозможно поместить ссылку в буфер обмена',
                variant: 'warning',
              },
            })
            alert(result.payment.formUrl)
          },
        )
      }
      member.disabled = false
    },
    addResultLink() {
      this.start.results.push({
        name: '',
        file: null,
      })
    },
    async changePayment(status) {
      if (this.selectedGroups.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            icon: 'BellIcon',
            text: 'Не выбраны группы',
            variant: 'danger',
          },
        })
        return false
      }

      await payment_module.createItem({
        groupIds: this.selectedGroups,
        isPayed: status === 'paid',
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Смена статуса оплаты прошла успешно',
            variant: 'success',
          },
        })
        this.getMembersFromStart()
        this.selectedGroups = []
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            icon: 'BellIcon',
            text: 'Ошибка смены статуса оплаты',
            variant: 'danger',
          },
        })
      })
    },
    saveItem() {
      this.start.sponsors_list = []
      this.sponsors.forEach(sponsor => {
        if (sponsor.logo) {
          this.start.sponsors_list.push({
            ...sponsor,
            logo: JSON.stringify(sponsor.logo),
          })
        }
      })

      this.start.results_list = (this.start?.results || []).map(e => ({
        name: e.name,
        fileID: e.fileID,
      }))

      let errorNetworks = []
      this.organizers.forEach(item => {
        const errorOrgLinks = item.social_networks.filter(social => !this.isExternalLink(social.url, social.code) || !social.code)
        errorNetworks = errorNetworks.concat(errorOrgLinks)
      })

      const errorLinks = this.socials.filter(social => !this.isExternalLink(social.url, social.code) || !social.code)
      errorNetworks = errorNetworks.concat(errorLinks)

      if (errorNetworks.length !== 0) {
        return
      }

      this.start.useful_links_list = this.useful_links
      this.start.social_networks_list = this.socials
      this.start.promocodes_list = this.promocodes.map(obj => {
        if (obj.template_id) {
          const { id, ...rest } = obj
          return rest
        }
        return obj
      })
      this.start.personal_discounts = this.personalDiscounts.map(obj => {
        if (obj.template_id) {
          const { id, ...rest } = obj
          return {
            ...rest,
            user_id: obj.user.id,
          }
        }
        return {
          ...obj,
          user_id: obj.user.id,
        }
      })
      this.start.kind_of_sports_list = this.kindOfSports_local
      this.start.distances_list = this.distanceMap(this.select_kinds_sport)

      delete this.start.results
      delete this.start.distances
      delete this.start.sponsors
      delete this.start.disabledDistances

      this.start.organizers_list = this.organizers.map(org => {
        if (org.messengers) {
          return {
            ...org,
            messengers: org.messengers.join(','),
            social_networks_list: org.social_networks,
          }
        }
        return {
          ...org,
          social_networks_list: org.social_networks,
        }
      })

      if (this.excelResultColumns.length > 0) {
        this.start.result_columns_json = JSON.stringify(this.excelResultColumns)
      } else {
        this.start.result_columns_json = null
      }

      if (this.$route.params.id === 'new') {
        this.createStart()
      } else {
        this.updateStart()
      }
    },
    distanceMap(distancesList) {
      return distancesList.map(distance => {
        const {
          static_price, stages, additional_fields, discounts, groups, date_prices, ...rest
        } = distance
        const mappedAdditionalFields = additional_fields?.map(field => ({
          ...field,
          options_list: field?.options,
        }))
        const mappedStages = stages?.map(stage => {
          const mappedStagesFields = stage?.additional_fields.map(field => ({
            ...field,
            options_list: field.options,
          }))
          return {
            ...stage,
            additional_fields_list: mappedStagesFields,
          }
        })

        return {
          static_price,
          additional_fields_list: mappedAdditionalFields,
          age_discounts_list: discounts,
          stages_list: mappedStages,
          groups_list: groups,
          date_prices_list: date_prices,
          ...rest,
        }
      })
    },
    async updateStart() {
      this.isloading = true
      try {
        await start_module
          .updateItem(this.start.id, this.start)
          .then(async () => {
            await this.updateDataFromStart()
            await this.getMembersFromStart()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Старт сохранен',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'warning',
              },
            })
          })
          .finally(() => {
            this.isloading = false
          })
      } catch (e) {
        this.isloading = false
        if (!e.notified) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'BellIcon',
              text: e.response.data.message,
              variant: 'warning',
            },
          })
        }
      }
    },
    async createStart() {
      this.isloading = true
      try {
        await start_module
          .createItem(this.start)
          .then(async response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Старт добавлен',
                variant: 'success',
              },
            })
            this.$router.push({ path: `/${response.data.id}`, query: { tab: 'general' } })
            await this.updateDataFromStart()
            await this.getMembersFromStart()
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.isloading = false
          })
      } catch (e) {
        this.isloading = false
        if (!e.notified) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'BellIcon',
              text: e.response.data.message,
              variant: 'danger',
            },
          })
        }
      }
    },
    debounceSearch(search, loading, fn) {
      clearTimeout(this.debounce)
      if (search.length >= 0) {
        this.debounce = setTimeout(fn, 600)
      }
    },
    getFilteredMembers(search, loading) {
      this.debounceSearch(search, loading, async () => {
        try {
          const data = await this.$http
            .get(
              `/member-start?start_id=${this.$route.params.id}&filterText=${search}`,
            )
            .then(e => e.data)
          this.members = [...data.rows]
        } catch (e) {
          console.error(e)
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">

.table-payment-row {
  display: flex;
  align-items: center;
}

.icons-container {
  display: flex;
  align-items: center;
  gap: 10px;

  .chevron-container {
    display: none;
  }

  .bi-chevron-down {
    cursor: pointer;
    margin: 0;
    transition: all 0.5s ease-out;

    path {
      fill: #6e6b7b;
    }
  }

  .rotate-chevron {
    transform: rotate(-180deg);
  }

  // label {
  //   margin: 0;
  //   transition: all 0.5s ease-out;
  // }

  // .chevron-container:checked + label {
  //   transform: rotate(-180deg);
  // }
}

.nav-bar {
  display: flex;
  align-items: center;
  margin: 0 -5px 10px -5px;

  &__item {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 20px;
    border-radius: 5px;

    margin: 0 5px;
    color: #636363;
    transition: 0.3s;

    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }

    .dark-layout & {
      color: #ffffff;

      &.active {
        color: #ffffff;
      }
    }

    &.active {
      background: #7367f0;
      color: #ffffff;
    }
  }
}

@import '@core/scss/vue/libs/vue-flatpicker.scss';
.upload-wrap {
  margin-bottom: 20px;
  // width: 40px;
  // height: 40px;
}

.custom_input {
  display: flex;
  width: 100%;

  input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .input-group-text {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.time-pick {
  margin-right: 15px;
}

.save-button {
  position: fixed !important;
  bottom: 30px;
  right: 15px;
  z-index: 1000;
}

.buttonsContainer {
  margin: 0 15px;
  justify-content: end;
  position: fixed !important;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  right: 0;
  bottom: 30px;
  z-index: 1000;

  .membersTabButtons {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
}

.vs-button-day {
  margin: 5px 0;
  background: #7367f0 !important;
  border: 1px solid #7367f0;
  color: white;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}

.tabs-day-block {
  margin-top: 20px;
}

.fade-tab-enter-active,
.fade-tab-leave-active {
  transition: all 0.3s;
}

.fade-tab-enter, .fade-tab-leave-to /* .fade-tab-leave-active below version 2.1.8 */
{
  top: 0;
  opacity: 0;
  position: absolute !important;
  transform: translate3d(-100%, 0, 0);
}

.fade-tab-leave-to /* .fade-tab-leave-active below version 2.1.8 */
{
  top: 0;
  opacity: 0;
  position: absolute !important;
  transform: translate3d(100%, 0, 0);
}

.switches {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.switch {
  font-size: 12px;

  &:first-child {
    @media (max-width: 767px) {
      margin-top: 16px;
    }
  }
}

.img-uploader {
  margin: 10px 0;
}

.links {
  display: flex;
  align-items: center;

  svg {
    transition: 0.3s;

    &:hover {
      cursor: pointer;
      transform: scale(1.3);
    }
  }

  &__input {
    margin: 10px 0;

    &:nth-child(2) {
      margin: 10px 10px;
    }

    &:last-child {
    }
  }
}

.organizer {
  &__wrap {
    display: flex;

    input {
      margin: 0 10px;
    }
  }

  &__switch {
    margin: 0 10px;
  }
}

.flex-wrapper {
  display: flex;
}

.promo {
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  &__item {
    position: relative;
    margin: 0 15px 10px 15px;
    display: flex;
    flex-direction: column;
    max-width: max-content;
    box-shadow: 0px 0px 23px -2px rgba(34, 60, 80, 0.2);
    border-radius: 20px;
    padding: 25px;

    &-del {
      position: absolute;
      top: 10px;
      right: 10px;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }
}

.discount {
  &__items {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    margin: 0 15px 10px 15px;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: max-content;
    box-shadow: 0px 0px 23px -2px rgba(34, 60, 80, 0.2);
    border-radius: 20px;
    padding: 25px;

    &-del {
      position: absolute;
      right: 9px;
      top: 5px;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }
}

.search-wrap {
  display: flex;
  justify-content: right;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__open-indicator {
  fill: #7367f0;
}

.b-table-details {
  td:first-child {
    padding: 0;
  }
}

.first-table {
  th {
    white-space: nowrap;
  }

  .b-table-has-details {
    background-color: #d1d0e8 !important;
  }

  tr {
    td {
      font-weight: 600;
    }
  }
}

.second-table {
  th {
    white-space: nowrap;
  }

  td:first-child {
    padding: 0.72rem 2rem;
  }

  tr {
    td {
      font-weight: 400;
    }
  }
}
</style>
